import request from '@/utils/request'


//明细分页列表
export function detailList(data) {
	return request({
		url: '/clock/getPageList',
		method: 'get',
		params: data
	})
}

//汇总列表
export function totalList(data) {
	return request({
		url: '/clock/getList',
		method: 'get',
		params: data
	})
}

//查询地图轨迹
// /clock/getMapLine


//导出
export function patrolTaskExport(data) {
	return request({
		url: '/nineTask/exportList',
		method: 'post',
		responseType: "blob",
		params: data
	})
}
export function getMapLine(data) {
	return request({
		url: '/clock/getMapLine',
		method: 'get',
		params: data
	})
}
export function getPageListByAddress(data) {
	return request({
		url: '/clock/getPageListByAddress',
		method: 'get',
		params: data
	})
}