<template>
	<div class="wrapper">
		<el-form :inline="true" :model="formInline" class="demo-form-inline">
			<el-form-item label="时间">
				<el-date-picker v-model="formInline.date" type="daterange" range-separator="至" format="yyyy-MM-dd"
					value-format="yyyyMMdd" start-placeholder="开始日期" end-placeholder="结束日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="姓名">
				<el-input v-model="formInline.name" placeholder="填写姓名"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSearch"><i class="el-icon-search"></i> 数据查询</el-button>
				<el-button type="primary" @click="locationSearch"><i class="el-icon-location-information"></i>
					位置查询</el-button>
				<el-button type="primary" @click="onSubmit"><i class="el-icon-top"></i> 导出</el-button>
			</el-form-item>
		</el-form>

		<el-table id="table-out" :data="tableData" :header-cell-style="{  fontSize: '14px', color: '#282828'}"
			:row-style="{ fontSize: '12px', color: '#6a6a6a'}" border stripe style="width: 100%">
			<el-table-column align="center" prop="jobmanname" label="姓名">
			</el-table-column>
			<el-table-column align="center" prop="pointaddess" label="打卡点">
			</el-table-column>
			<el-table-column align="center" prop="createtime" label="打卡时间">
			</el-table-column>
			<el-table-column align="center" prop="gridname" label="网格">
			</el-table-column>
		</el-table>

		<div class="pagebox">
			<el-pagination :hide-on-single-page="totalRow?true:false" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50]"
				:page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalRow">
			</el-pagination>
		</div>
		<el-dialog title="位置查询" width="80%" :visible.sync="dialogTableVisible" :before-close="handleClose">
			<el-form :inline="true" :model="chaxun" class="demo-form-inline">
				<el-form-item label="时间">
					<el-date-picker value-format="yyyyMMdd" v-model="chaxun.time" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="姓名">
					<el-input v-model="chaxun.name" placeholder="填写姓名"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getditu"><i class="el-icon-search"></i> 查询</el-button>
				</el-form-item>
			</el-form>
			<div id="trackMapContainer" :style="{height: amapboxHeight+'px'}" class="amap-box"></div>
		</el-dialog>
	</div>

</template>

<script>
	import {
		lazyAMapApiLoaderInstance
	} from 'vue-amap';
	import FileSaver from "file-saver";
	import XLSX from "xlsx";
	import yidakaIcon from '@/assets/imgs/mark_zhengchang.png';
	let clientHeight = document.documentElement.clientHeight;
	import {
		detailList,
		getMapLine,
		getPageListByAddress
	} from '@/api/records'
	export default {
		name: "records",
		components: {},
		props: {},
		data() {
			return {
				amapboxHeight: clientHeight - 200,
				yidakaIcon,
				pageSize: 10,
				currentPage: 1,
				totalRow: 0,
				formInline: {
					date: '',
					name: ''
				},
				tableData: [],
				dialogTableVisible:false,
				polylinePath: [],
				zoom: '',
				center: '',
				markers: '',
				chaxun:{
					time:'',
					name:''
				}
			}
		},
		watch: {},
		created() {},
		mounted() {
			this.getList();
		},
		computed: {},
		methods: {
			getday(time){
				let a = new Date(time)
				let year = a.getFullYear()
				let mon = a.getMonth() + 1
				let month = mon > 9 ? mon : '0' + mon
				let day = a.getDate() > 9 ? a.getDate() : '0' + a.getDate()
				return year + '' + month + '' + day;
			},
			handleClose: function(done) {
				let that = this;
				that.chaxun.time = '';
				that.jobmanname = '';
				done();
			},
			getditu(){
				let that = this;
				getPageListByAddress({
					yearmoenthdaybegin: that.chaxun.time?that.chaxun.time[0]:'',
					yearmoenthdayend: that.chaxun.time?that.chaxun.time[1]:'',
					jobmanname: that.chaxun.name
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						let detail = res.detail;
						that.polylinePath = [];
						if(detail.length == 0){
							that.$message.warning('暂无数据');
						}
						for (let item of detail) {
							let zuobiao = item.pointxy.split(',');
							that.polylinePath.push(zuobiao);
							item.position = zuobiao;
							item.icon = that.yidakaIcon;
							item.clock_time = item.createtime;
						}
						that.zoom = 9;
						that.center = detail.length > 0?detail[0].position:[118.62841,32.059];
						that.markers = detail;
				
						that.initMap()
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			onSearch() {
				this.currentPage = 1;
				this.pageSize = 9999999;
				this.getList();
			},
			locationSearch() {
				let that = this;
				that.dialogTableVisible = true;
				// 获取当前日期
				const currentDate = new Date();
				
				// 获取当前月的第一天
				const currentMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
				
				// 获取当前月的最后一天
				const nextMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
				const currentMonthLastDay = new Date(nextMonthFirstDay.getTime() - 1);
				that.chaxun.time = [that.getday(currentDate),that.getday(currentDate)];
				that.getditu();
				
			},
			initMap() {
				let that = this;
				lazyAMapApiLoaderInstance.load().then(() => {
			
					that.map = new AMap.Map('trackMapContainer', {
						resizeEnable: true,
						center: that.center,
						zoom: 14
					});
			
					that.map.addControl(new AMap.ToolBar());
			
					// 创建点
					let markers = []
					that.markers.forEach(item => {
						let marker = new AMap.Marker({
							icon: item.icon,
							position: item.position,
							title: `打卡时间：${item.clock_time}`
						});
			
						var text = new AMap.Text({
							text: `${item.jobmanname}<br/ >${item.clock_time}`,
							anchor: 'center', // 设置文本标记锚点
							cursor: 'pointer',
							offset: new AMap.Pixel(0, -50),
							style: {
								'padding': '4px 8px',
								'border-radius': '.25rem',
								'margin-bottom': '40px',
								'background-color': '#3e86ff',
								'border-width': 0,
								'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
								'text-align': 'center',
								'font-size': '12px',
								'color': '#fff'
							},
							position: item.position
						});
			
						text.setMap(that.map);
			
						// 将创建的点标记添加到已有的地图实例：
						markers.push(marker);
					})
			
					that.map.add(markers)
			
					// 自动适配视图
					that.map.setFitView();
				})
			},
			getLocationList() {

			},
			getList() {
				detailList({
					yearmoenthdaybegin: this.formInline.date ? this.formInline.date[0] : '',
					yearmoenthdayend: this.formInline.date ? this.formInline.date[1] : '',
					jobmanname: this.formInline.name,
					page: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						this.tableData = res.detail.list;
						this.totalRow = res.detail.totalRow;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			onSubmit() {
				var xlsxParam = {
					raw: true
				}
				var wb = XLSX.utils.table_to_book(
					document.querySelector('#table-out'),
					xlsxParam
				)
				var wbout = XLSX.write(wb, {
					bookType: 'xlsx',
					bookSST: true,
					type: 'array'
				})
				try {
					FileSaver.saveAs(
						new Blob([wbout], {
							type: 'application/octet-stream'
						}),
						'巡防记录明细.xlsx'
					)
				} catch (e) {
					if (typeof console !== 'undefined') console.log(e, wbout)
				}
				return wbout
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.pagebox {
		padding: 20px 0;
	}
</style>