<template>
	<div class="wrapper">
		<el-form :inline="true" :model="formInline" class="demo-form-inline">
			<el-form-item label="时间">
				<el-date-picker v-model="formInline.month" type="month" format="yyyy-MM" value-format="yyyy-MM"
					placeholder="选择月">
				</el-date-picker>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getList"><i class="el-icon-search"></i> 查询</el-button>
				<el-button type="primary" @click="daochu"><i class="el-icon-top"></i> 导出</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="tableData" :header-cell-style="{  fontSize: '14px', color: '#282828'}"
			:row-style="{ fontSize: '12px', color: '#6a6a6a'}" border stripe style="width: 100%">
			<el-table-column align="center" prop="time" label="日期">
			</el-table-column>
			<el-table-column align="center" prop="jobmanname" label="姓名">
			</el-table-column>
			<el-table-column align="center" prop="num" label="打卡数">
				<template slot-scope="scope">
					<span class="clocknum" @click="getDetail(scope.row)">{{ scope.row.num }}</span>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="name" label="操作">
				<template slot-scope="scope">
					<el-button type="primary" @click="getTrack(scope.row)"><i class="el-icon-map-location"></i>
						轨迹查询</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-table id="table-out" v-show="false" :data="tableData"
			:header-cell-style="{  fontSize: '14px', color: '#282828'}"
			:row-style="{ fontSize: '12px', color: '#6a6a6a'}" border stripe style="width: 100%">
			<el-table-column align="center" prop="time" label="日期">
			</el-table-column>
			<el-table-column align="center" prop="jobmanname" label="姓名">
			</el-table-column>
			<el-table-column align="center" prop="num" label="打卡数">
				<template slot-scope="scope">
					<span class="clocknum" @click="getDetail(scope.row)">{{ scope.row.num }}</span>
				</template>
			</el-table-column>
		</el-table>

		<el-dialog title="打卡明细" width="80%" :visible.sync="dialogTableVisible" :before-close="handleClose">
			<el-form :inline="true" :model="chaxun" class="demo-form-inline">
				<el-form-item label="时间">
					<el-date-picker value-format="yyyyMMdd" v-model="chaxun.time" type="daterange" range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getditu"><i class="el-icon-search"></i> 查询</el-button>
				</el-form-item>
			</el-form>
			<div id="trackMapContainer" :style="{height: amapboxHeight+'px'}" class="amap-box"></div>
		</el-dialog>


	</div>
</template>

<script>
	import yidakaIcon from '@/assets/imgs/mark_zhengchang.png'
	let clientHeight = document.documentElement.clientHeight;
	import {
		totalList,
		getMapLine
	} from '@/api/records'
	import {
		lazyAMapApiLoaderInstance
	} from 'vue-amap';
	import FileSaver from "file-saver";
	import XLSX from "xlsx";
	export default {
		name: "records",
		props: {},
		data() {
			return {
				amapboxHeight: clientHeight - 200,
				formInline: {
					month: '',
				},
				yidakaIcon,
				tableData: [],
				dialogTableVisible: false,
				polylinePath: [],
				zoom: '',
				center: '',
				markers: '',
				chaxun: {
					time: ''
				},
				jobmanname:''
			}
		},
		watch: {},
		created() {},
		mounted() {
			let a = new Date();
			let year = a.getFullYear();
			let mon = a.getMonth() + 1;
			let month = mon > 9 ? mon : '0' + mon;
			this.formInline.month = year + '-' + month;
			this.getList();
		},
		computed: {},
		methods: {
			getday(time){
				let a = new Date(time)
				let year = a.getFullYear()
				let mon = a.getMonth() + 1
				let month = mon > 9 ? mon : '0' + mon
				let day = a.getDate() > 9 ? a.getDate() : '0' + a.getDate()
				return year + '' + month + '' + day;
			},
			handleClose: function(done) {
				let that = this;
				that.chaxun.time = '';
				that.jobmanname = '';
				done();
			},
			daochu() {
				var xlsxParam = {
					raw: true
				}
				var wb = XLSX.utils.table_to_book(
					document.querySelector('#table-out'),
					xlsxParam
				)
				var wbout = XLSX.write(wb, {
					bookType: 'xlsx',
					bookSST: true,
					type: 'array'
				})
				try {
					FileSaver.saveAs(
						new Blob([wbout], {
							type: 'application/octet-stream'
						}),
						'巡防记录汇总.xlsx'
					)
				} catch (e) {
					if (typeof console !== 'undefined') console.log(e, wbout)
				}
				return wbout
			},
			onSubmit() {
				// console.log('submit!');
			},
			getditu(){
				let that = this;
				getMapLine({
					yearmoenthdaybegin: that.chaxun.time?that.chaxun.time[0]:'',
					yearmoenthdayend: that.chaxun.time?that.chaxun.time[1]:'',
					jobmanname: that.jobmanname
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						let detail = res.detail;
						if(detail.length == 0){
							that.$message.warning('暂无数据');
						}
						that.polylinePath = [];
						for (let item of detail) {
							let zuobiao = item.pointxy.split(',');
							that.polylinePath.push(zuobiao);
							item.position = zuobiao;
							item.icon = that.yidakaIcon;
							item.clock_time = item.createtime;
						}
						that.zoom = 9;
						that.center = detail.length > 0?detail[0].position:[118.62841,32.059];
						that.markers = detail;
				
						that.initMap()
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			getTrack(a) {
				let that = this;
				that.dialogTableVisible = true;
				// 获取当前日期
				const currentDate = new Date();
				
				// 获取当前月的第一天
				const currentMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
				
				// 获取当前月的最后一天
				const nextMonthFirstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
				const currentMonthLastDay = new Date(nextMonthFirstDay.getTime() - 1);
				that.chaxun.time = [that.getday(currentMonthFirstDay),that.getday(currentMonthLastDay)];
				that.jobmanname = a.jobmanname;
				that.getditu();
				
			},
			getList() {
				totalList({
					yearmoenthday: this.formInline.month.split('-').join('')
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						let detail = res.detail;
						for (let item of detail) {
							item.time = this.formInline.month;
						}
						this.tableData = detail;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			getDetail(row) {
				// console.log(row)
				this.dialogTableVisible = true;
			},
			initMap() {
				let that = this;
				lazyAMapApiLoaderInstance.load().then(() => {

					that.map = new AMap.Map('trackMapContainer', {
						resizeEnable: true,
						center: that.center,
						zoom: 14
					});

					that.map.addControl(new AMap.ToolBar());

					// 创建点
					let markers = []
					that.markers.forEach(item => {
						let marker = new AMap.Marker({
							icon: item.icon,
							position: item.position,
							title: `打卡时间：${item.clock_time}`
						});

						var text = new AMap.Text({
							text: `${item.jobmanname}<br/ >${item.clock_time}`,
							anchor: 'center', // 设置文本标记锚点
							cursor: 'pointer',
							offset: new AMap.Pixel(0, -50),
							style: {
								'padding': '4px 8px',
								'border-radius': '.25rem',
								'margin-bottom': '40px',
								'background-color': '#3e86ff',
								'border-width': 0,
								'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
								'text-align': 'center',
								'font-size': '12px',
								'color': '#fff'
							},
							position: item.position
						});

						text.setMap(that.map);

						// 将创建的点标记添加到已有的地图实例：
						markers.push(marker);
					})

					that.map.add(markers)


					// 绘制轨迹
					let polyline = new AMap.Polyline({
						map: that.map,
						path: that.polylinePath,
						showDir: true,
						lineJoin: 'round',
						lineCap: 'round',
						strokeColor: "#85cc70", //线颜色
						strokeWeight: 14, //线宽
						strokeOpacity: 1, //线透明度
						strokeStyle: "solid" //线样式
					});

					// 自动适配视图
					that.map.setFitView();
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.noRecordTxt {
		font-size: 16px;
	}

	.amap-box {
		width: 100%;
	}

	.clocknum {
		font-weight: bold;
		color: #409eff;
		cursor: pointer;
		transition: 0.3s all;

		&:hover {
			text-decoration: underline;
		}
	}
</style>