<template>
	<div class="wrapper">
		<div class="menusbtn">
			<el-switch v-model="menuType" inactive-text="记录明细" active-text="记录汇总">
			</el-switch>
		</div>
		<div v-if="!menuType">
			<detail-cpn></detail-cpn>
		</div>
		<div v-else>
			<total-cpn></total-cpn>
		</div>
	</div>
</template>

<script>
	import detailCpn from './detailCpn.vue'
	import totalCpn from './totalCpn.vue'
	export default {
		name: "records",
		components: {
			detailCpn,
			totalCpn
		},
		props: {},
		data() {
			return {
				menuType: false,
				pageSize: 10,
				currentPage: 1,
				totalRow: 40,
				totalForm: {
					user: '',
					region: ''
				},
				detailForm: {
					month: '',
				},
				tableData: [{
					date: '2016-05-02',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1518 弄'
				}, {
					date: '2016-05-04',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1517 弄'
				}, {
					date: '2016-05-01',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1519 弄'
				}, {
					date: '2016-05-03',
					name: '王小虎',
					address: '上海市普陀区金沙江路 1516 弄'
				}],
				detailTableData: [{
					name: '王小虎',
				}, {
					name: '王小虎',
				}],
			}
		},
		watch: {},
		created() {},
		mounted() {},
		computed: {},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.menusbtn {
		text-align: right;
		margin-bottom: 10px;
	}
</style>